<template>
  <div class="version">
    <h1>7.22.1614</h1>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: "AppVersion",
  mounted() {
    this.appReady(true);
  },
  methods: {
    ...mapMutations([
      'appReady',
    ]),
  },
}
</script>

<style scoped>
  h1 {
    color: white;
  }
  .version {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
